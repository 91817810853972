<template>
  <div>
    <b-form class="mb-2" inline>
      <div class="w-100 pr-1">
        <b-form-input @blur="search" v-model="filter.text" class="w-100" :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('PROJECT')})  | capitalize"
                      id="search-input"></b-form-input>
      </div>
      <div class="w-50 pr-1 mt-1">
        <b-datepicker @input="search" v-model="filter.from" class="w-100" :placeholder="$t('FILTER_FROM_LABEL', {catalog: $t('PROJECT')})  | capitalize"></b-datepicker>
      </div>
      <div class="w-50 pr-1 mt-1">
        <b-datepicker @input="search" v-model="filter.to" class="w-100" :placeholder="$t('FILTER_TO_LABEL', {catalog: $t('PROJECT')})  | capitalize"></b-datepicker>
      </div>
    </b-form>
  </div>
</template>

<script>
import {CapitalFilter, VModel} from "@/mixins";

export default {
  name: "ProjectListFilter",
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if(this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>
